<template>
  <div class="dp-empty">
    <div class="dp-empty-icon">
      <Icon :name="iconName" color="dpGrey88" :width="44" :height="44" />
    </div>

    <h2 class="dp-empty-text">
      {{ text }}
    </h2>

    <p v-if="subtext" class="dp-empty-subtext">
      {{ subtext }}
    </p>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      default: 'information'
    },

    text: {
      type: String,
      default: 'データがありません'
    },

    subtext: {
      type: String,
      default: ''
    }
  },

  setup: () => {
    return {
      WORDS
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-empty {
  display: grid;
  place-items: center;

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.dp-empty-icon {
  margin-bottom: 16px;
  line-height: 1;
}

.dp-empty-text {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
}

.dp-empty-subtext {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.45;
  font-weight: normal;
  text-align: center;
  white-space: pre-wrap;
}
</style>
