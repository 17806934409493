var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dp-empty" }, [
    _c(
      "div",
      { staticClass: "dp-empty-icon" },
      [
        _c("Icon", {
          attrs: {
            name: _vm.iconName,
            color: "dpGrey88",
            width: 44,
            height: 44,
          },
        }),
      ],
      1
    ),
    _c("h2", { staticClass: "dp-empty-text" }, [
      _vm._v(" " + _vm._s(_vm.text) + " "),
    ]),
    _vm.subtext
      ? _c("p", { staticClass: "dp-empty-subtext" }, [
          _vm._v(" " + _vm._s(_vm.subtext) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }